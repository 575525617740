import { IzzyLogo } from '#app/components/icons/izzy-logo'
import { Button } from '#app/components/ui/button'
import {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
	DialogFooter,
	DialogClose,
} from '#app/components/ui/dialog'
import { appStoreLink, googlePlayLink } from '#app/utils/misc'

export const DownloadApp = ({ children }: { children: React.ReactNode }) => {
	const preloadDownloadQRCode = () => {
		const img = new Image()
		img.src = '/img/download-app-qr.png'
	}
	return (
		<Dialog>
			<DialogTrigger onMouseEnter={preloadDownloadQRCode} asChild>
				{children}
			</DialogTrigger>
			<DialogContent className="xs:max-w-[420px]">
				<IzzyLogo color="#054DB3" />
				<DialogHeader>
					<DialogTitle>
						Stáhněte si aplikaci pro plné využít našich služeb
					</DialogTitle>
					<DialogDescription>
						Naše aplikace vám umožní plnohodnotné využívání služeb Izzy s.r.o.
						Pro stáhnutí naskenujte jeden z níže přiložených QR kódů.
					</DialogDescription>
					<div className="grid items-stretch gap-2 pt-3">
						<div className="flex items-center justify-start gap-3">
							<div className="h-2 w-2 rounded-full bg-rose-400" />
							<div className="text-sm leading-tight text-zinc-800">
								Chat s paní na úklid
							</div>
						</div>
						<div className="flex items-center justify-start gap-3">
							<div className="h-2 w-2 rounded-full bg-rose-400" />
							<div className="text-sm leading-tight text-zinc-800">
								Přehled o úklidech
							</div>
						</div>
						<div className="flex items-center justify-start gap-3">
							<div className="h-2 w-2 rounded-full bg-rose-400" />
							<div className="text-sm leading-tight text-zinc-800">
								Další benefity
							</div>
						</div>
					</div>
				</DialogHeader>
				<img
					height="200"
					width="200"
					src="/img/download-app-qr.png"
					alt="QR code"
					className="mx-auto max-w-[200px] max-xs:hidden"
				/>
				<div className="my-3 flex justify-evenly gap-3">
					<a href={appStoreLink}>
						<img
							width={135}
							height={44}
							src="/img/app-store.svg"
							alt="app-store"
						/>
					</a>
					<a href={googlePlayLink}>
						<img
							width={135}
							height={44}
							src="/img/google-play.svg"
							alt="google-play"
						/>
					</a>
				</div>
				<DialogFooter>
					<DialogClose asChild>
						<Button className="w-full" type="button">
							Mám staženo
						</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
