import { Form, useSubmit } from '@remix-run/react'
import { useRef } from 'react'
import { DropdownMenuItem } from './ui/dropdown-menu'

export const Logout = () => {
	const formRef = useRef<HTMLFormElement>(null)
	const submit = useSubmit()

	return (
		<DropdownMenuItem
			asChild
			// this prevents the menu from closing before the form submission is completed
			onSelect={event => {
				event.preventDefault()
				submit(formRef.current)
			}}
			className="cursor-pointer"
		>
			<Form action="/logout" method="POST" ref={formRef}>
				<button type="submit">Odhlásit se</button>
			</Form>
		</DropdownMenuItem>
	)
}
