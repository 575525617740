import { Link } from '@remix-run/react'
import { useRootLoader } from '#app/hooks/use-root-loader'
import { useOptionalUser } from '#app/utils/user'
import { Logout } from './logout'
import {
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubTrigger,
	DropdownMenuPortal,
	DropdownMenuSubContent,
	DropdownMenuLabel,
} from './ui/dropdown-menu'

export const MobileMenu = () => {
	const user = useOptionalUser()
	const { profile } = useRootLoader()
	return (
		<DropdownMenuContent className="w-56">
			{user && profile && !('errors' in profile) && (
				<>
					<DropdownMenuLabel>
						{profile.name} {profile.surname}
					</DropdownMenuLabel>
					<DropdownMenuSeparator />
				</>
			)}
			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/dashboard/order-1">
					Objednat úklid
				</Link>
			</DropdownMenuItem>
			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/download-app">
					Stáhnout aplikaci
				</Link>
			</DropdownMenuItem>
			<DropdownMenuSeparator />

			{user && (
				<>
					<DashboardMenu />

					<DropdownMenuSeparator />
				</>
			)}

			<PublicMenu />

			<DropdownMenuSeparator />

			{user ? (
				<Logout />
			) : (
				<DropdownMenuItem asChild>
					<Link prefetch="intent" to="/login">
						Přihlásit se
					</Link>
				</DropdownMenuItem>
			)}
		</DropdownMenuContent>
	)
}

export const DashboardMenu = () => {
	return (
		<>
			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/dashboard/overview">
					Nástěnka
				</Link>
			</DropdownMenuItem>

			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/dashboard/orders">
					Úklidy
				</Link>
			</DropdownMenuItem>

			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/dashboard/profile">
					Profil
				</Link>
			</DropdownMenuItem>
		</>
	)
}

export const PublicMenu = () => {
	return (
		<>
			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/how-it-works">
					Jak to funguje
				</Link>
			</DropdownMenuItem>
			<DropdownMenuSub>
				<DropdownMenuSubTrigger>
					<span>Služby</span>
				</DropdownMenuSubTrigger>
				<DropdownMenuPortal>
					<DropdownMenuSubContent>
						<DropdownMenuItem asChild>
							<Link prefetch="intent" to="/b2c">
								Domácnosti
							</Link>
						</DropdownMenuItem>
						<DropdownMenuSeparator />
						<DropdownMenuItem asChild>
							<Link prefetch="intent" to="/b2b">
								Firmy
							</Link>
						</DropdownMenuItem>
					</DropdownMenuSubContent>
				</DropdownMenuPortal>
			</DropdownMenuSub>
			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/cleaning-providers">
					Paní na úklid
				</Link>
			</DropdownMenuItem>
			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/pricing">
					Ceník
				</Link>
			</DropdownMenuItem>
			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/about">
					O nás
				</Link>
			</DropdownMenuItem>
			<DropdownMenuItem asChild>
				<Link prefetch="intent" to="/contact">
					Kontakt
				</Link>
			</DropdownMenuItem>
		</>
	)
}
