import { Link, useLocation } from '@remix-run/react'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Logout } from '#app/components/logout'
import { DashboardMenu, PublicMenu } from '#app/components/mobile-menu'
import { Button } from '#app/components/ui/button.tsx'
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuPortal,
	DropdownMenuContent,
	DropdownMenuSeparator,
	DropdownMenuLabel,
} from '#app/components/ui/dropdown-menu.tsx'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '#app/components/ui/tooltip'
import { useRootLoader } from '#app/hooks/use-root-loader'
import { api } from '#app/utils/api'
import { useOptionalUser } from '#app/utils/user.ts'

export function UserMenu() {
	const user = useOptionalUser()
	const location = useLocation()
	const { profile } = useRootLoader()

	const [isDefaultImage, setDefaultImage] = useState(false)

	if (!user)
		return (
			<TooltipProvider>
				<Tooltip delayDuration={300}>
					<TooltipTrigger asChild>
						<Button
							asChild
							variant="ghost"
							className="cursor-pointer hover:bg-white/20"
						>
							<Link
								prefetch="intent"
								to="/login"
								className="text-sm font-medium leading-normal text-white"
							>
								<img
									src="/img/user.png"
									alt="user"
									className={twMerge(
										'h-8 w-8 rounded-full object-cover',
										!location.pathname.startsWith('/dashboard') && 'invert',
									)}
								/>
							</Link>
						</Button>
					</TooltipTrigger>
					<TooltipContent side="bottom">
						<p>Přihlaste se</p>
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		)
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					asChild
					variant="ghost"
					className="cursor-pointer hover:bg-white/20"
				>
					<span className="flex items-center gap-2">
						{profile && !('errors' in profile) ? (
							<img
								src={api.getProviderImageUrl(profile._id)}
								onError={e => {
									e.currentTarget.src = '/img/user.png'
									setDefaultImage(true)
								}}
								alt={profile.name}
								className={twMerge(
									'h-8 w-8 rounded-full object-cover',
									!location.pathname.startsWith('/dashboard') &&
										isDefaultImage &&
										'invert',
								)}
							/>
						) : (
							<img
								src="/img/user.png"
								alt="Uživatel"
								className={twMerge(
									'h-8 w-8 rounded-full object-cover',
									!location.pathname.startsWith('/dashboard') && 'invert',
								)}
							/>
						)}
					</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent
					sideOffset={8}
					align="center"
					className="min-w-[320px]"
				>
					{profile && !('errors' in profile) && (
						<DropdownMenuLabel>
							{profile.name} {profile.surname}
						</DropdownMenuLabel>
					)}
					<DropdownMenuSeparator />
					{location.pathname.startsWith('/dashboard') ? (
						<PublicMenu />
					) : (
						<DashboardMenu />
					)}

					<Logout />
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}
